import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

interface NewReversLogoIconProps extends IconPropsInterface {
  dotColor: string;
}

const NewReversLogoIcon: FunctionComponent<NewReversLogoIconProps> = ({
  color = 'black',
  size = '24px',
  dotColor,
}) => (
  <Icon size={size} viewBox="0 0 372 319" color={color}>
    <rect width="372" height="319" fill="none" />
    <path
      d="M253 194C258.5 194 263.167 196 267 200C271 203.833 273 208.5 273 214C273 219.5 271 224.25 267 228.25C263.167 232.083 258.5 234 253 234C247.5 234 242.75 232.083 238.75 228.25C234.917 224.25 233 219.5 233 214C233 208.5 234.917 203.833 238.75 200C242.75 196 247.5 194 253 194Z"
      fill={dotColor}
    />
    <path
      d="M158.301 84C165.132 84 171.818 84.9378 178.359 86.8134C184.899 88.6891 190.786 91.5746 196.018 95.4701C201.396 99.3657 205.611 104.271 208.663 110.187C211.861 115.958 213.459 122.883 213.459 130.963C213.459 139.475 211.279 146.833 206.919 153.037C202.704 159.097 197.108 163.858 190.132 167.321C183.155 170.639 175.597 172.587 167.458 173.164L220 229H180.103L129.305 169.701V229H99V84H158.301ZM129.305 112.134V148.709C132.793 149.863 136.644 150.801 140.859 151.522C145.22 152.1 149.58 152.388 153.941 152.388C162.661 152.388 169.565 150.657 174.652 147.194C179.885 143.587 182.501 138.537 182.501 132.045C182.501 124.975 179.739 119.925 174.216 116.896C168.838 113.721 162.225 112.134 154.377 112.134H129.305Z"
      fill="currentColor"
    />
  </Icon>
);

NewReversLogoIcon.displayName = 'NewReversLogoIcon';

export default NewReversLogoIcon;
