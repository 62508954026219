import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  BUTTON_TYPES,
  REVALIDATE_MODES,
} from '@savgroup-front-common/constants';
import { QuoteClientAnswer } from '@savgroup-front-common/types/src/Quotation/QuoteClientAnswer';

import { Button } from '../../../../atoms/button';
import { Didactic } from '../../../../atoms/Didactic';
import { FormGroup } from '../../../../atoms/Form';
import { Heading } from '../../../../atoms/Heading';
import { ModalHeader } from '../../../../atoms/modal';
import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';
import { RadioCardHookForm } from '../../../../molecules/Form';
import { AdaptedQuotationNewModel } from '../../types/AdaptedQuotationNewModel';

import { schema } from './ConfirmQuotationRejectModalContent.schema';
import { $ActionRow } from './ConfirmQuotationRejectModalContent.styles';
import messages from './messages';

interface ConfirmQuotationRejectModalContentProps {
  onRejectWithReturn: () => void;
  onRejectWithDestruction: () => void;
  onClose: () => void;
  quotation: AdaptedQuotationNewModel;
}

const ConfirmQuotationRejectModalContent: FC<
  ConfirmQuotationRejectModalContentProps
> = ({ onRejectWithReturn, onRejectWithDestruction, onClose, quotation }) => {
  const { register, formState, handleSubmit } = useForm<{
    rejectOption:
      | QuoteClientAnswer.REJECTED_WITH_DESTRUCTION
      | QuoteClientAnswer.REJECTED_WITH_RETURN;
  }>({
    resolver: yupResolver(schema),
    mode: REVALIDATE_MODES.ALL,
  });

  const intl = useIntl();

  const handleRejectWithReturn = handleSubmit(({ rejectOption }) => {
    if (rejectOption === QuoteClientAnswer.REJECTED_WITH_DESTRUCTION) {
      onRejectWithDestruction();

      return undefined;
    }

    if (rejectOption === QuoteClientAnswer.REJECTED_WITH_RETURN) {
      onRejectWithReturn();

      return undefined;
    }

    return undefined;
  });

  return (
    <form onSubmit={handleRejectWithReturn}>
      <ModalHeader>
        <Heading level={3}>
          <SafeFormattedMessageWithoutSpread message={messages.title} />
        </Heading>
      </ModalHeader>

      {(quotation.returnFee?.amountIncludedTax?.amount ||
        quotation.destructionFee?.amountIncludedTax?.amount) && (
        <Didactic>
          <SafeFormattedMessageWithoutSpread
            message={messages.rejectQuotationDidactic}
          />
        </Didactic>
      )}

      <FormGroup>
        <RadioCardHookForm
          data-testid="rejectWithReturnOption"
          name="rejectOption"
          value={QuoteClientAnswer.REJECTED_WITH_RETURN}
          title={messages.returnOption}
          register={register}
          label={
            quotation.returnFee
              ? intl.formatNumber(
                  quotation.returnFee.amountIncludedTax.amount,
                  {
                    style: 'currency',
                    currency: quotation.returnFee.amountIncludedTax.currency,
                  },
                )
              : undefined
          }
        />
      </FormGroup>
      <FormGroup>
        <RadioCardHookForm
          data-testid="rejectWithDestructionOption"
          name="rejectOption"
          value={QuoteClientAnswer.REJECTED_WITH_DESTRUCTION}
          title={messages.destructionOption}
          register={register}
          label={
            quotation.returnFee
              ? intl.formatNumber(
                  quotation.destructionFee.amountIncludedTax.amount,
                  {
                    style: 'currency',
                    currency:
                      quotation.destructionFee.amountIncludedTax.currency,
                  },
                )
              : undefined
          }
        />
      </FormGroup>

      <$ActionRow>
        <Button tertiary type={BUTTON_TYPES.BUTTON} onClick={() => onClose()}>
          <SafeFormattedMessageWithoutSpread message={messages.buttonCancel} />
        </Button>
        <Button type={BUTTON_TYPES.SUBMIT} danger disabled={!formState.isValid}>
          <SafeFormattedMessageWithoutSpread message={messages.buttonReject} />
        </Button>
      </$ActionRow>
    </form>
  );
};

ConfirmQuotationRejectModalContent.displayName =
  'ConfirmQuotationRejectModalContent';

export default ConfirmQuotationRejectModalContent;
